/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "stdout.fm 46번째 에러 로그에서는 복식부기 가계부 후잉에 대해서 이야기를 나눴습니다."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://what3words.com/welcome.necks.twinge"
  }, "what3words | Addressing the world")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://whooing.com/"
  }, "후잉가계부(whooing)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.podbbang.com/ch/13803"
  }, "팟캐스트 돈없수다 방송듣기, : 팟빵")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.podbbang.com/ch/13803?e=22361642"
  }, "돈없수다 - 009. 가계부를 복식부기로 쓰면 어떨까?(흥반장)")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
